"use strict";

import * as constants from "./constants";

/**
 * アクセスデバイスの閲覧状態が横長であるかをブラウザ表示サイズで判定します
 * @returns {boolean}
 */
export function isLandscape() {
  // 横が縦より長ければ横長と判定（正方形は横長と扱う）
  return window.innerWidth >= window.innerHeight;
}

/**
 * カテゴリ要素か判定します
 * @param {HTMLElement} targetCategoryElement
 * @returns {boolean}
 */
export function isCategory(targetCategoryElement) {
  return targetCategoryElement && targetCategoryElement.classList.contains("category");
}

/**
 * カテゴリ要素が表示されているか判定します
 * @param {HTMLElement} targetCategoryElement
 * @returns {boolean}
 */
export function isViewCategory(targetCategoryElement) {
  if (!isCategory(targetCategoryElement)) return false;
  const computedStyle = window.getComputedStyle(targetCategoryElement);
  return computedStyle.display === "flex";
}

/**
 * カテゴリ要素が開いているか判定します
 * @param {HTMLElement} targetCategoryElement
 * @returns {boolean}
 */
export function isOpenCategory(targetCategoryElement) {
  if (!isViewCategory(targetCategoryElement)) return false;
  const booksElement = [...targetCategoryElement.children].find((el) => el.matches(".books"));
  return booksElement && booksElement.style.display === "flex";
}

/**
 * フロアマップが開いているか判定します
 */
export function isOpenFloorMap() {
  return document.getElementById("sidebar")?.classList.contains("active") || false;
}

/**
 * タテオキから開かれた新しいタブか判定します
 */
export function isNewTab() {
  return window.name === constants.TATEOKI_TAB_NAME;
}

/**
 * モニタ表示エリア内の指定された位置に位置するエレメントを取得します
 * @param {string} position - ELEMENT_POSITION
 * @param {number} [customPosition] - "custom" 位置を使用する場合に指定するピクセル位置
 * @returns {Element|null}
 */
export function getElementAtPosition(position, customPosition = 0) {
  const windowWidth = window.innerWidth;
  let targetPosition;

  switch (position) {
    case constants.ELEMENT_POSITION.CENTER:
      targetPosition = window.scrollX + windowWidth / 2;
      break;
    case constants.ELEMENT_POSITION.LEFT:
      targetPosition = window.scrollX + constants.CATEGORY_MARGIN_WIDTH;
      break;
    case constants.ELEMENT_POSITION.RIGHT:
      targetPosition = window.scrollX + windowWidth;
      break;
    case constants.ELEMENT_POSITION.CUSTOM:
      targetPosition = window.scrollX + customPosition;
      break;
    default:
      throw new Error("無効な position 引数です。ELEMENT_POSITION を指定してください。");
  }

  // 範囲内にあるかどうかをチェック
  function isInRange(elementStart, elementEnd) {
    return elementStart <= targetPosition && targetPosition <= elementEnd;
  }

  // reverse することで最も深い要素からマッチングをおこなう
  const elements = [...document.querySelectorAll(".category")].reverse();

  for (let element of elements) {
    const spine = [...element.children].find((child) => child.classList.contains("category_spine_link"));
    const spineRect = spine.getBoundingClientRect();
    let elementStart = window.scrollX + spineRect.left;
    let elementEnd = window.scrollX + spineRect.right;

    // カテゴリ背表紙部の領域判定
    if (isInRange(elementStart, elementEnd)) {
      return element;
    }

    const info = [...element.children].find((child) => child.classList.contains("category-info"));
    const infoRect = info.getBoundingClientRect();
    elementStart = window.scrollX + infoRect.left;
    elementEnd = window.scrollX + infoRect.right;

    // カテゴリ詳細情報の領域判定
    if (isInRange(elementStart, elementEnd)) {
      return info;
    }

    const books = [...element.children].find((child) => child.classList.contains("books"));
    const booksRect = books.getBoundingClientRect();
    elementStart = window.scrollX + booksRect.left;
    elementEnd = window.scrollX + booksRect.right;

    // カテゴリに所属する背表紙部の領域判定
    if (isInRange(elementStart, elementEnd)) {
      return books;
    }
  }

  return null;
}

/**
 * 要素がターゲット位置の範囲内にあるかを判定します
 * @param {Element} positionElement - 位置の基準となる要素
 * @param {string} position - 位置の指定 ('CENTER' or 'LEFT')
 * @returns {boolean} - 要素が指定位置の範囲内にあるかどうか
 */
export function isElementWithinBounds(positionElement, position) {
  // 対象要素の背表紙リンクを取得
  const categorySpine = positionElement.querySelector(".category_spine_link");
  if (!categorySpine) return false;

  // 要素の境界を取得
  const rect = categorySpine.getBoundingClientRect();

  // 要素の左端と右端の位置を取得（スクロール位置も考慮）
  const elementStart = rect.left + window.scrollX;
  const elementEnd = rect.right + window.scrollX;

  // 要素が中央に配置されているかの判定
  const isCentered = position === constants.ELEMENT_POSITION.CENTER;

  // 中央に配置されている場合はスクロール位置にウィンドウ幅の半分を加えた位置をターゲットとする
  // 左揃えの場合はスクロール位置そのものをターゲット位置とする
  const targetPosition = isCentered ? window.scrollX + window.innerWidth / 2 : window.scrollX;

  // 要素がターゲット位置の範囲内にあるかどうかを判定
  const isWithinBounds = elementStart + constants.CATEGORY_MARGIN_WIDTH <= targetPosition && targetPosition <= elementEnd;

  // 要素が範囲内にあるかどうかを返す
  return isWithinBounds;
}
