/**
 *  broadcast.js
 *
 *  Created by Satoshi Ishizu
 *  Copyright tateoki Inc. All rights reserved.
 */
"use strict";

const channel = new BroadcastChannel("org-tateoki-status");
const tabId = Math.random().toString(36).substr(2, 9); // タブごとの識別子

/**
 * 別タブの関数を実行するリクエストを送信します
 * @param {string} functionName
 * @param {Array} args
 */
export function callFunctionInOtherTab(functionName, args = []) {
  channel.postMessage({ type: "execute", functionName, args, sender: tabId });
}

/**
 * [受信側] 指定関数を実行するロジックです
 * @param {object} availableFunctions 実行可能な関数群
 */
export function handleBroadcastMessages(availableFunctions) {
  channel.onmessage = (event) => {
    const { type, functionName, args, sender } = event.data;

    if (sender === tabId) return; // 自分が送ったメッセージは無視

    if (type === "execute" && availableFunctions[functionName]) {
      availableFunctions[functionName](...args); // 関数実行
    }
  };
}
